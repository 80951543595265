@import 'npm:@ibm/plex/css/ibm-plex.min.css';

:root {
    --main: #0a9ed3;
    --secondary: #737373; 
    --bg: #010302;

    /* asi docela osklivy hack jak vycentrovat obrazky v tymu v ramecku*/
    --team-member-outer-width: 18rem;
    /* 0.88 je tak magicka konstanta, aby byl obrazek presne o malicko vetsi nez vnitrni prostor ramecku */
    --team-member-inner-width: calc(0.88*var(--team-member-outer-width));
    --team-member-inner-offset: calc(
        (var(--team-member-outer-width)
            - var(--team-member-inner-width)
        )/2
    );
    --team-member-width: calc(
        var(--team-member-outer-width)
        + 1*var(--team-member-inner-offset)
    );
}


body {
    background-color: #fff;
    font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
    font-weight: 700;
    color: var(--secondary);
    text-align: center;
    font-size: 1.1rem;
    overflow-x: hidden;
}

body, ul {
    margin: 0
}

ul {
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    height: fit-content
}

ul li {
    margin: 0
}

a.button {
    background-color: var(--bg);
    border-radius: 10rem;
    padding: .6rem 2rem;
    font-size: 1.2rem;
    text-decoration: none;
    text-transform: uppercase;
    margin: 2rem 0;
    color: #fff;
    border: 1px solid #fff
}

a.button:hover {
    color: var(--main);
    text-decoration: 1px underline var(--main);
    background-color: #fff
}

h1 {
    font-size: 2.6rem
}

h1, h2 {
    border-top: 7px solid var(--main);
    max-width: 100vw
}

h2 {
    font-size: 2.5rem
}

a[href^="mailto:"] {
    color: #fff
}

a[href^="mailto:"]:hover {
    background-color: #fff;
    color: var(--secondary)
}

address {
    font-style: normal
}

address, address > p {
    margin: 0
}

section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

header {
    width: 100%;
    position: fixed;
    z-index: 10;
    pointer-events: none
}

header nav {
    pointer-events: auto;
    text-transform: uppercase;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 2vw;
    box-sizing: border-box
}

header nav #logo {
    display: grid;
}
header nav #logo > img {
    grid-column: 1;
    grid-row: 1;

    width: 100%;
    pointer-events: auto;
    max-height: 15vh;
    animation-name: heartBeat;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    background-size: contain
}

header nav ul {
    justify-content: flex-end;
    text-align: right;
}

header nav ul li {
    margin: 0 .4rem;
    font-size: 1.5rem
}

header nav ul li a {
    pointer-events: auto;
    text-decoration: none;
    color: var(--bg);
    text-align: right
}

header nav ul li a:hover {
    color: var(--main);
    background-color: var(--bg)
}

header nav ul li.on-dark-section a {
    color: #fff
}

header nav ul li.on-dark-section a:hover {
    color: var(--main)
}

section#section-main {
    background-image: url("/images/bg_top_cutout.png?as=webp&quality=20");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}


section#section-main {
    height: 100vh;
    box-shadow: 0 0 30px hsla(0, 0%, 49%, .38)
}

section#section-main div#time-and-place {
    font-size: 1.5rem;
    position: absolute;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    bottom: 10vh
}

section#section-main div#time-and-place time {
    color: var(--secondary);
    font-size: 2.5rem;
    text-decoration: none
}

section#section-main div#time-and-place address, section#section-main div#time-and-place span {
    margin: .3rem 0 .3rem .5rem;
    letter-spacing: .4rem
}

/* https://github.com/parcel-bundler/parcel/issues/2474 */
/* section#section-main svg#arrow { */
section#section-main>svg {
    position: absolute;
    bottom: 1vh;
    height: 2.5rem;
    animation-name: slideInDown;
    animation-iteration-count: infinite;
    animation-duration: 4s;
    max-width: 12vw
}

div.bg-colors {
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: -1;
    background-size: 30rem;
    background-position-x: center;
    background-position-y: center
}

section#section-claim {
    padding: 2rem;
    background-color: #fff
}

section#section-claim p#main-claim {
    font-size: 1.7rem;
    margin: 1rem;
    max-width: 70rem
}

section#section-startovac {
    padding: 2rem;
    background-image: url("/images/pattern.png")
}

section#section-startovac p {
    font-size: 1.7rem;
    margin: 1rem;
    max-width: 70rem
}

section#section-countdown {
    color: var(--bg);
    text-transform: uppercase;
    margin: .5rem 0;
    background-color: #fff;
    padding: 1.5rem
}

section#section-countdown span {
    letter-spacing: 1.2rem;
    margin-left: 1.2rem
}

section#section-countdown span#countdown-title {
    letter-spacing: .3rem;
    margin: 0 0 0 .3rem;
    font-size: 1.3rem
}

section#section-countdown span#days {
    font-size: 3.9rem
}

section#section-countdown span#hours {
    font-size: 1.8rem
}

section#section-countdown span#minutes {
    font-size: 1.5rem
}

section#section-lineup {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    background-color: var(--bg);
    color: #fff;
    padding: 2rem 0
}

section#section-lineup div.lineup {
    display: inline;
    flex-direction: column;
    justify-content: center;
    margin: 2rem 3rem;
    font-size: 1.5rem
}

section#section-lineup div.lineup h2 {
    display: inline
}

section#section-lineup div.lineup div.lineup-entry {
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

section#section-lineup div.lineup div.lineup-entry span {
    text-align: left;
    margin: .5rem
}

div#sponsors {
    background-color: #fff;
    padding: 3rem 1rem;
    width: 100%;
    box-sizing: border-box
}

div#sponsors img.sponsor {
    margin: 1rem 2rem;
    object-fit: contain;
    width: auto;
    max-width: 8rem;
    max-height: 15vh
}

footer div#sponsors .sponsor-kudyznudy {
    flex-basis: 100%;
    padding-bottom: 2rem;
}

footer div#sponsors .sponsor-kudyznudy img {
    max-height: 9rem;
}

footer div#sponsors .sponsor-cez {
    max-width: 15rem !important;
}


footer div#sponsors img.sponsor {
    margin: 1rem
}

section#section-artists {
    padding: 0 8vw 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: url("/images/pattern.png");
    background-color: #fff
}

section#section-artists div#artists {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 43rem;
    min-width: 20rem
}

section#section-artists div#artists a {

    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative
}

section#section-artists div#artists a picture {
    width: 100%
}

section#section-artists div#artists a img {
    width: 100%
}

section#section-artists div#artists a span {
    /*position: absolute;
    color: #fff;
    font-size: 2.4rem;
    left: 0;
    right: 0*/
}

section#section-artists div#artists a:hover picture {
}

section#section-gallery-teaser {
    background: url("/images/gallery-teaser-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover
}

section#section-gallery-teaser {
    padding: 0 10vw;
    box-shadow: 0 0 30px rgba(0, 0, 0, .37)
}

section#section-gallery-teaser h2 {
    color: #fff
}

section#section-gallery-teaser div#gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center
}

section#section-gallery-teaser div#gallery a {
    min-width: 20rem;
    width: 25%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--main);
    position: relative
}

section#section-gallery-teaser div#gallery a picture {
    transform: scale(1.2);
    transition: .3s ease-in-out
}

section#section-gallery-teaser div#gallery a img {
    width: 100%
}

section#section-gallery-teaser div#gallery a span {
    text-transform: uppercase;
    opacity: 0;
    margin: 0;
    position: absolute;
    background-color: #fff;
    color: #000;
    background-clip: border-box;
    border: 1px solid #fff;
    border-radius: 10rem;
    padding: .6rem 2rem;
    mix-blend-mode: screen
}

section#section-gallery-teaser div#gallery a:hover picture {
    transform: scale(1.08);
    opacity: .6
}

section#section-gallery-teaser div#gallery a:hover span {
    opacity: 1;
    transition: .3s
}

section#section-gallery {
    padding-top: 20vh;
    background-image: url("/images/bg_top.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-color: #efefef
}

section#section-gallery div#gallery {
    min-height: 100vh;
    width: 70vw
}

section#section-gallery div#gallery h1, section#section-gallery div#gallery h2 {
    display: inline-block
}

section#section-program {
    padding: 20vh 0.3em 0 0.3em;
    background-image: url("/images/bg_top.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-color: #efefef
}
section#section-program div#program {
    max-width: 65em;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    margin-bottom: 1.5em;
}

section#section-team {
    padding-top: 20vh;
    background-image: url("/images/bg_top.jpg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-color: #efefef
}

section#section-team div#team {
    width: 100%;
    margin-bottom: 1.5em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

section#section-team div.team-section {
    max-width: calc(5*var(--team-member-width));
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

section#section-team div#team div.team-member {
    display: flex;
    flex-direction: column;
    width: var(--team-member-width);
    box-sizing: border-box;
}

section#section-team div#team div.team-member img {
    width: 100%;
    object-fit: contain
}

section#section-team div#team div.team-member span {
    margin: .5rem
}

section#section-team div#team div.team-member span.member-description {
    margin: 0;
    font-size: .8rem
}

section#section-team div#team div.team-member div.outer {
    position:relative;
    width: var(--team-member-outer-width);
    margin-bottom: 1.5rem;
    margin-top: 1rem;
}


section#section-team div#team div.team-member img.frame {
    position:absolute;
}
section#section-team div#team div.team-member img.inner {
    position:relative;
    left: var(--team-member-inner-offset);
    width: var(--team-member-inner-width);
    top: var(--team-member-inner-offset);
    height: var(--team-member-inner-width);
}

section#section-icons {
    flex-direction: row;
    padding: 3rem 15vw;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: #fff
}

section#section-icons div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 12rem
}

section#section-icons div img {
    max-height: 5rem;
    margin: 1rem
}

section#section-icons div span {
    text-transform: uppercase
}

section#section-location > div#location {
    background: url("/images/kudyknam_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom
}

section#section-location {
    padding: 0;
    flex-direction: row;
    flex-wrap: wrap
}

section#section-location > div {
    flex: 1;
    height: 25rem
}

section#section-location > div#location {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

section#section-location > div#location div#transport-ways {
    font-size: 1.3rem
}

section#section-location > div#location div#transport-ways div.transport-way {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%
}

section#section-location > div#location div#transport-ways div.transport-way div.transport-way-icon {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center
}

section#section-location > div#location div#transport-ways div.transport-way div.transport-way-icon svg {
    width: 100%;
    height: 75%
}

section#section-location > div#location div#transport-ways div.transport-way svg {
    height: 1rem
}

section#section-location > div#location div#transport-ways div.transport-way p {
    margin: 0 1rem
}

section#section-location > div#location address {
    margin: 3rem
}

section#section-location > div#map {
    background-color: #fff
}

ul#social-list {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    z-index: 10
}

ul#social-list > li {
    padding: .5rem
}

ul#social-list > li a svg {
    width: 3rem;
    max-width: 12vw;
    fill: var(--bg)
}

ul#social-list > li a svg.on-dark-section path {
    fill: #fff
}

ul#social-list > li:hover {
    background-color: var(--bg)
}

ul#social-list > li:hover svg, ul#social-list > li:hover svg.on-dark-section path {
    fill: var(--main)
}

footer {
    margin-top: .5rem;
    border-top: 7px solid var(--main)
}

footer div#footer-info {
    background-color: var(--secondary);
    padding: 4rem 0;
    color: #fff
}

footer div#footer-info div#emails {
    display: inline-flex;
    flex-direction: column
}

footer div#footer-info p {
    margin: 1rem
}

.bg-colors-loaded {
    background-image: url("/images/pattern_colors.png");
}

img.logo-img-hidden {
    visibility: hidden;
}

footer div#sponsors {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 3rem 1rem;
    width: 100%;
    flex-wrap: wrap;
    box-sizing: border-box
}

footer div#sponsors .sponsor {
    margin: 1rem 2rem;
    object-fit: contain;
    width: auto;
    max-width: 8rem;
    max-height: 7rem;
}

[hidden] {
    display: none;
}

@keyframes heartBeat {
    82.5% {
        transform: scale(1)
    }
    86% {
        transform: scale(1.02)
    }
    89.5% {
        transform: scale(1)
    }
    93% {
        transform: scale(1.02)
    }
    to {
        transform: scale(1)
    }
}

@keyframes slideInDown {
    25% {
        transform: translateZ(0)
    }
    50% {
        transform: translate3d(0, -.7rem, 0);
        visibility: visible
    }
    75% {
        transform: translateZ(0)
    }
}

@media only screen and (max-width: 750px) {
    header > nav ul li a {
        background-color: #fff
    }

    header > nav ul li a:hover {
        color: var(--main);
        background-color: var(--bg)
    }

    header > nav ul li.on-dark-section a {
        background-color: var(--main)
    }

    header > nav ul li.on-dark-section a:hover {
        color: var(--main);
        background-color: #fff
    }

    section#section-main div#countdown {
        bottom: 10vh;
        position: relative
    }

    section#section-main div#time-and-place {
        font-size: 1rem;
        bottom: 13vh
    }

    section#section-main div#time-and-place address, section#section-main div#time-and-place span {
        line-height: .8rem
    }

    section#section-main div#time-and-place time {
        font-size: 2rem
    }

    section#section-icons {
        flex-direction: column
    }

    section#section-lineup div.lineup {
        margin: 2rem 1rem
    }

    section#section-claim p#main-claim {
        font-size: 1.5rem
    }

    section#section-gallery div#gallery {
        width: 90vw
    }

    section#section-gallery-teaser {
        padding: 0
    }

    section#section-team {
        padding-left: 5vw;
        padding-right: 5vw
    }

    section#section-location > div {
        width: 100%
    }

    section#section-location {
        flex-direction: column
    }

    ul#social-list > li {
        padding: .2rem
    }
}

nav .nav-lg {
    display: inline;
}
nav .nav-sm {
    display: none;
}
nav ul {
    line-height: 1.5;
}

@media screen and (max-width: 600px) {
    nav .nav-sm-enabled .nav-lg {
        display: none;
    }
    nav .nav-sm-enabled .nav-sm {
        display: inline;
    }
}
